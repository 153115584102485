import React  from "react";
import JobSearchForm from "./JobSearchForm/JobSearchForm";
import config from "../Config/Config";




function TopBanner() {

    return (
        <>
            <div className="container-fluid topbanner">
                <div className="container">
                    <div className="jobbanner">
                        <h4>Find Job on {config.COMPANY_NAME} </h4>
                        <p className="my-4">Search Jobs opening for which we are hiring right now.</p>
                        <JobSearchForm />
                    </div>
                </div>
            </div>
        </>
    )
}
export default TopBanner;